import React from "react"
// import { Link } from "gatsby"
import Footer from "./footer"
import Nav from "./nav"
import PlainMark from "./icons/plain-mark"

class BlogLayout extends React.Component {
  render() {
    const { children, className } = this.props

    return (
      <div className={`flex flex-col min-h-screen ${className}`}>
        <Nav />
        <PlainMark className="fixed w-64 left-0 right-0 top-0 sm:-ml-24 -mt-24 opacity-25 text-jnl-yellow-dark z-0 " />
        <PlainMark className="fixed w-24 left-0 top-0 sm:ml-12 lg:ml-32 sm:mt-96 lg:mt-48 opacity-25 text-jnl-red-medium z-0 " />

        <main className="relative flex-grow z-10">{children}</main>
        <Footer className="relative z-10" />
      </div>
    )
  }
}

export default BlogLayout

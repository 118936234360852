import React from "react"
import { format, parse } from "date-fns"
import { Link } from "gatsby"
import Badge from "../badge"
import HyvorTalk from "hyvor-talk-react"

const PostMeta = ({ post, className }) => {
  let published = format(
    parse(post.postDate, "yyyy-MM-dd", new Date()),
    "M/dd/yyyy"
  )

  return (
    <div className={`flex flex-row space-x-2 ${className}`}>
      <span className="text-gray-500 uppercase text-xs font-thin tracking-widest">
        Posted {published}
      </span>
      <span className="text-jnl-teal-dark uppercase text-xs font-thin px-2 space-x-2">
        {post.category &&
          post.category.map(category => (
            <Link
              to={`/blog/categories/${category.slug}`}
              className="hover:text-jnl-teal-light underline tracking-wider"
              key={category.slug}
            >
              {category.name}
            </Link>
          ))}
      </span>
      <Badge className="bg-gray-100 ml-0 leading-tight">
        <HyvorTalk.CommentCount websiteId={602} id={post.slug} />
      </Badge>
    </div>
  )
}

export default PostMeta

import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

const Post = ({ cover, slug }) => {
  return (
    <div className="w-full md:w-1/3 mb-4">
      <Link to={`/blog/${slug}`}>
        <Image fluid={cover.fluid} className="rounded-md" />
      </Link>
    </div>
  )
}

const RelatedPosts = ({ posts }) => {
  return (
    <section className="h-72">
      <div className="container mx-auto">
        <div className="mb-4">
          <div className="border-gray-200 border-b">
            <h4 className="inline-block text-2xl font-bold tracking-wide text-jnl-red-medium">
              Related Posts
            </h4>
          </div>
        </div>
        <div>
          <div className="flex space-x-4 mb-12">
            {posts
              ? posts.map(post => {
                  return <Post {...post} key={post.slug} />
                })
              : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default RelatedPosts

import React from "react"
import { Link } from "gatsby"

import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const SidebarAdvert = ({ url, title }) => {
  const data = useStaticQuery(graphql`
    query {
      ad: file(relativePath: { eq: "ebooks/rsh-sidebar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  let ad = data.ad.childImageSharp.fluid

  return (
    <div
      className={`md:w-64 lg:w-72 cursor-pointer md:fixed md:top-0 md:pt-16 lg:pt-32`}
    >
      <Link to={url} target="_blank" rel="noopener noreferrer" title={title}>
        <Image fluid={ad} alt={title} />
      </Link>
    </div>
  )
}

export default SidebarAdvert

import React from "react"
import { graphql } from "gatsby"
import BlogLayout from "../components/blog-layout"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"
import PostMeta from "../components/blog/post-meta"
import Author from "../components/blog/author"
import RelatedPosts from "../components/blog/related-posts"
import Image from "gatsby-image"
import HyvorTalk from "hyvor-talk-react"
import rehypeReact from "rehype-react"
import Capture from "../components/blog/capture"
import SidebarAdvert from "../components/blog/sidebar-ad"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  Fragment: React.Fragment,
  components: { "inline-capture": Capture },
}).Compiler

export default class PostTemplate extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const postNode = this.props.data.contentfulBlogPost
    const post = postNode

    return (
      <BlogLayout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.title}
          image={post.cover.fluid.src}
          description={post.introContent.excerpt.html}
        />
        <section>
          <div className="container lg:max-w-screen-lg mx-auto px-6 py-16 flex flex-col md:flex-row">
            <div className="w-full md:w-3/4 flex-grow">
              <PageHeader>{post.title}</PageHeader>
              <PostMeta post={post} className="mb-8" />
              <Image fluid={post.cover.fluid} className="mb-12 rounded-md" />
              <div className="markdown">
                {renderAst(post.content.body.htmlAst)}
              </div>
              <Author author={post.author} />
              {post.relatedPosts ? (
                <RelatedPosts posts={post.relatedPosts} />
              ) : null}
              <HyvorTalk.Embed
                websiteId={602}
                id={post.slug}
                loadMode="scroll"
              />
            </div>
            <div className="md:w-1/4 px-8 relative">
              <SidebarAdvert
                url="ready-set-homeschool"
                title="Get my homeschool getting started guide today"
                sticky={true}
              />
            </div>
          </div>
        </section>
      </BlogLayout>
    )
  }
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      slug
      tags
      category {
        name
        slug
      }
      postDate
      videoEmbedCode
      youTubeLink
      cover {
        id
        fluid {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
        }
      }
      author {
        name
        about: bio {
          bio
        }
        photo {
          file {
            url
          }
        }
      }
      introContent {
        excerpt: childMarkdownRemark {
          html
        }
      }
      content {
        body: childMarkdownRemark {
          htmlAst
          timeToRead
        }
      }
      relatedPosts {
        title
        slug
        cover {
          fluid {
            srcWebp
            srcSetWebp
            srcSet
            src
            sizes
            aspectRatio
          }
        }
      }
    }
  }
`

import React from "react"

const Author = ({ author }) => {
  const { name, about, photo } = author

  return (
    <div className="flex flex-col border border-gray-200 p-4 mt-8 mb-8">
      <h5 className="mb-4 text-jnl-red-medium">About the Author</h5>
      <div className="flex lg:space-x-4">
        <img
          className="h-24 w-24 rounded-full"
          src={photo.file.url}
          alt="Ashley Weaver"
        />
        <div className="ml-3">
          <p className="text-sm leading-5 font-medium text-gray-700 group-hover:text-gray-900 mb-2">
            {name}
          </p>
          <p className="text-xs leading-4 font-medium text-gray-500 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150">
            {about.bio}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Author

import React from "react"

const Badge = ({ children, className = "bg-gray-200" }) => (
  <span
    className={`inline-flex items-center px-2 py-1 rounded-full 
    text-xs font-medium leading-4 text-gray-600 ${className}`}
  >
    {children}
  </span>
)

export default Badge
